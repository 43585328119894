import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import {
  Form,
  Button,
  Col,
  Table,
  Row,
  Card,
  InputGroup,
  Modal,
} from "react-bootstrap";
import ClientDDComponent from "../Client/ClientDDComponent";
import Select from "react-select";
import { getCurrentDate } from "../../Utils/commonFunction";
import { format } from "date-fns";
import ChargeComponent from "../Charge/ChargeComponent";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";

const emptyInvoiceDetails = {
  InvoiceDetailID: 0,
  ChargeID: 0,
  ChargeName: "",
  Qty: 1,
  Rate: 0,
  Amount: 0,
  Remarks: "",
};

const emptyInvoice = {
  Invoice_ID: 0,
  Invoice_No: "",
  Invoice_Date: getCurrentDate(),
  Client_ID: 0,
  Gross_Amount: 0,
  Disc_Per: 0,
  Disc_Amount: 0,
  Net_Amount: 0,
  Total_ReceivedAmount: 0,
  Due_Amount: 0,
  Remarks: "",
  PaymentType_ID: 0,
  Received_Amount: 0,
  Invoice_ReceivedDate: getCurrentDate(),
};

export default function InvoiceComponent({
  InvoiceID,
  editedInvoiceData,
  getInvoiceData,
}) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [formData, setFormData] = useState(emptyInvoice);
  const [invoiceDetails, setInvoiceDetails] = useState([emptyInvoiceDetails]);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [ChargeOptions, setChargeOptions] = useState([]);
  const [selectedChargeNames, setSelectedChargeNames] = useState([""]);
  const [isChargeModalOpen, setIsChargeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // validation

  const [clientDDValidation, setClientDDValidation] = useState(false);
  const [rowNumberForNewChargeId, setRowNumberForNewChargeId] = useState(0);

  //  ============================================================================================================================================//

  useEffect(() => {
    getChargeName();
    api
      .get("Invoice/AllPaymentTypeName")
      .then(({ data }) => setPaymentTypeOptions(data.data))
      .catch(handleApiError);
  }, [refreshKey]);

  const getChargeName = async () => {
    try {
      const response = await api.get("Charge/AllCharge");
      const newData = response.data.data;
      setChargeOptions(newData);
      return newData;
    } catch (error) {
      handleApiError(error);
      return [];
    }
  };
  //  ============================================================================================================================================//

  useEffect(() => {
    if (editedInvoiceData) {
      setFormData({
        Invoice_ID: editedInvoiceData.invoiceID,
        Invoice_No: editedInvoiceData.invoiceNo,
        Invoice_Date: format(editedInvoiceData.invoiceDate, "yyyy-MM-dd"),
        Client_ID: editedInvoiceData.clientID,
        Gross_Amount: editedInvoiceData.grossAmount,
        Disc_Per: editedInvoiceData.discPer,
        Disc_Amount: editedInvoiceData.discAmount,
        Net_Amount: editedInvoiceData.netAmount,
        Total_ReceivedAmount: editedInvoiceData.totalReceivedAmount,
        Due_Amount: editedInvoiceData.dueAmount,
        Remarks: editedInvoiceData.remarks,
        PaymentType_ID: 0,
      });

      const updatedInvoiceDetails =
        editedInvoiceData.listInvoiceDetailDataModel.map((detail, index) => {
          const ChargeName =
            ChargeOptions.find((charge) => charge.chargeID === detail.chargeID)
              ?.chargeName || "";
          setSelectedChargeNames((prevChargeNames) => {
            const updatedChargeNames = [...prevChargeNames];
            updatedChargeNames[index] = ChargeName;
            return updatedChargeNames;
          });

          return {
            InvoiceDetailID: detail.invoiceDetailID,
            ChargeID: detail.chargeID,
            ChargeName: ChargeName,
            Qty: detail.qty,
            Rate: detail.rate,
            Amount: detail.amount,
            Remarks: detail.remarks,
          };
        });
      setInvoiceDetails(updatedInvoiceDetails);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedInvoiceData]);

  //  ============================================================================================================================================//
  const insertOrUpdateInvoice = (event) => {
    setIsLoading(true);
    debugger;
    if (formData.Client_ID === 0) {
      setClientDDValidation(formData.Client_ID === 0);
      setIsLoading(false); // Set loading to false when validation fails
      return;
    }
    if (invoiceDetails[0].ChargeID === 0) {
      setIsLoading(false); // Set loading to false when validation fails
      return;
    }

    const data = {
      InvoiceID: formData?.Invoice_ID || 0,
      InvoiceDate: formData.Invoice_Date,
      clientID: formData.Client_ID,
      GrossAmount: formData.Gross_Amount,
      DiscPer: formData.Disc_Per,
      DiscAmount: formData.Disc_Amount,
      NetAmount: formData.Net_Amount,
      ReceivedAmount: formData.Received_Amount,
      DueAmount: formData.Due_Amount,
      Remarks: formData.Remarks,
      PaymentTypeID: formData.PaymentType_ID,
      InvoiceReceiveDate: formData.Invoice_ReceivedDate,
      ListInvoiceDetailDataModel: invoiceDetails,
    };

    const endpoint =
      formData.Invoice_ID === 0
        ? "Invoice/InsertInvoice"
        : "Invoice/EditInvoice";

    api[formData.Invoice_ID === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          // setAreaDDValidationError(false);
          // setSourceTypeDDValidationError(false);
          setClientDDValidation(false);
          toast.success(result.data.message);
          if (InvoiceID) {
            InvoiceID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError)
      .finally(() => {
        resetForm(); // Reset form whether success or failure
        setIsLoading(false); // Set loading to false after API call completes
      });
  };

  //  Input Change Functions  ============================================================================================================================================//

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Convert the value to a number for comparison
    const newValue = parseFloat(value);

    // If the field is 'Received_Amount', perform the validation
    if (name === "Received_Amount") {
      const grossAmount = parseFloat(formData.Gross_Amount);

      // Ensure the received amount does not exceed the gross amount
      if (newValue > grossAmount) {
        Swal.fire({
          icon: "error",
          title: "Invalid Amount",
          text: "Received amount cannot be greater than the gross amount.",
          confirmButtonText: "OK",
        });
        return; // Prevent further execution
      }
    }

    // Update the formData if the validation passed
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // ======= Function For Set Payment Type ==============================================

  const handlePaymentType = (option) => {
    setSelectedPaymentOption(option);
    setFormData((prevData) => ({ ...prevData, PaymentType_ID: option.value }));
  };

  // ======= Function For Set Invoice Qty And Ret  =================================================

  const handleInputChangeOfDetails = (e, index) => {
    const { name, value } = e.target;
    const qty =
      name === "Qty" ? parseFloat(value) : invoiceDetails[index]?.Qty || 0;
    const rate =
      name === "Rate" ? parseFloat(value) : invoiceDetails[index]?.Rate || 0;
    const Amount = qty * rate;

    const updatedInvoiceDetails = [...invoiceDetails];
    updatedInvoiceDetails[index] = {
      ...updatedInvoiceDetails[index],
      [name]: value,
      Amount: isNaN(Amount) ? "" : Amount,
    };
    const newGrossAmount = updatedInvoiceDetails.reduce(
      (sum, row) => sum + (row.Amount || 0),
      0
    );

    setFormData((prevData) => ({
      ...prevData,
      Gross_Amount: newGrossAmount,
      Net_Amount: newGrossAmount,
    }));

    setInvoiceDetails(updatedInvoiceDetails);
  };

  // ======= Function For Set Invoice Charge DD =================================================
  const handleChargeChange = (option, rowNumber, newRest) => {
    debugger
    // let ChargeRate = 0;
    // if (newRest == null) {
    //   ChargeRate =
    //     ChargeOptions.find((charge) => charge.chargeID === option.value)
    //       ?.rate || "";
    // } else {
    //   ChargeRate = newRest;
    // }

    let ChargeRate = 0;

    // Find the charge object
    let charge = ChargeOptions.find((charge) => charge.chargeID === option.value);

    if (newRest == null) {
      ChargeRate = charge?.rate || "";
    } else {
      ChargeRate = newRest;
    }

    if (charge) {
      charge.ChargeName = option.label; // Update the ChargeName property
    }

    const Amount = invoiceDetails[rowNumber].Qty * ChargeRate;
    const updatedInvoiceDetails = [...invoiceDetails];
    updatedInvoiceDetails[rowNumber] = {
      ...updatedInvoiceDetails[rowNumber],
      ChargeID: option.value,
      Rate: ChargeRate,
      Amount: Amount,
     // ChargeName: charge.ChargeName
    };

    const newGrossAmount = updatedInvoiceDetails.reduce(
      (sum, row) => sum + (row.Amount || 0),
      0
    );

    const TotalAmount =
      formData.Disc_Per > 0
        ? (newGrossAmount * formData.Disc_Per) / 100
        : formData.Disc_Amount;

    setFormData((prevData) => ({
      ...prevData,
      Gross_Amount: newGrossAmount,
      Net_Amount: newGrossAmount - TotalAmount,
      Disc_Amount: TotalAmount,
    }));

    setInvoiceDetails(updatedInvoiceDetails);
    const updatedChargeNames = [...selectedChargeNames];
    updatedChargeNames[rowNumber] = option.label;
    setSelectedChargeNames(updatedChargeNames);
  };

  // ======= Function For Set Invoice Discount=================================================
  const handleDiscount = (event) => {
    const { name, value } = event.target;
    if (name == "Disc_Amount") {
      let TotalAmount = formData.Gross_Amount - value;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        Net_Amount: TotalAmount,
      }));
    }
    if (name == "Disc_Per") {
      let TotalAmount = (formData.Gross_Amount * value) / 100;
      let FinalAmount = formData.Gross_Amount - TotalAmount;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        Disc_Amount: TotalAmount,
        Net_Amount: FinalAmount,
      }));
    }
  };

  // other Functions  ============================================================================================================================================//

  const addRow = () => {
    const newInvoiceDetails = { ...emptyInvoiceDetails };
    setInvoiceDetails((prevDetails) => [...prevDetails, newInvoiceDetails]);
  };

  const removeRow = (rowNumber) => {
    const updatedInvoiceDetails = invoiceDetails.filter(
      (_, index) => index !== rowNumber
    );
    setInvoiceDetails(updatedInvoiceDetails);
    const updatedChargeNames = selectedChargeNames.filter(
      (_, index) => index !== rowNumber
    );
    setSelectedChargeNames(updatedChargeNames);
    const newGrossAmount = updatedInvoiceDetails.reduce(
      (sum, row) => sum + (row.Amount || 0),
      0
    );

    setFormData((prevData) => ({
      ...prevData,
      Gross_Amount: newGrossAmount,
      Net_Amount: newGrossAmount,
    }));
  };

  const resetForm = () => {
    setFormData(emptyInvoice);
    setInvoiceDetails([emptyInvoiceDetails]);
    setSelectedPaymentOption(null);
    setSelectedChargeNames([""]);
  };

  const closeChargeModal = async (Charge_id) => {
    if (Charge_id != null) {
      const newCharge = await getChargeName();
      const ChargeName =
        newCharge.find((charge) => charge.chargeID === Charge_id)?.chargeName ||
        "";

      const option = {
        value: Charge_id,
        label: ChargeName,
      };

      const newRest =
        newCharge.find((charge) => charge.chargeID === option.value)?.rate ||
        "";

      handleChargeChange(option, rowNumberForNewChargeId, newRest);
      setRowNumberForNewChargeId(0);
      setIsChargeModalOpen(false);
    } else {
      setRowNumberForNewChargeId(0);
      setIsChargeModalOpen(false);
    }
  };

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <Spinner animation="" variant="primary">
            <span class="loader"></span>
          </Spinner>
        </div>
      )}

      <Card>
        <Card.Header>
          <div className=" d-flex justify-content-between">
            <span className="text-start fw-bold">Invoice Information</span>
            <span className="text-end fw-bold">
              InvoiceNo :{" "}
              {formData.Invoice_No === "" ? "0" : formData.Invoice_No}
            </span>
          </div>
        </Card.Header>
        <Card.Body>
          <Form noValidate>
            <Row>
              <Col md={4} sm={4} lg={4}>
                <Form.Group controlId="Invoice_Name">
                  <Form.Label>Invoice Date:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i class="fa fa-calendar"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      name="Invoice_Date"
                      value={formData.Invoice_Date}
                      onChange={handleInputChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <ClientDDComponent
                onSelectClient={(clientID) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    Client_ID: clientID,
                  }));
                  setClientDDValidation(false);
                }}
                ValidationError={clientDDValidation}
                selectedClient={formData.Client_ID}
                key={`ClientDDComponent-${refreshKey}`}
              />
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <span>Invoice Detail Information</span>
        </Card.Header>
        <Card.Body>
          <Row>
            <Table bordered style={{ border: "#20336b" }}>
              <thead style={{ color: "#20336b" }}>
                <tr>
                  <th style={{ textAlign: "left" }}>Charge Name</th>
                  <th style={{ textAlign: "right" }}>Remarks</th>
                  <th style={{ width: "100px", textAlign: "right" }}>Qty</th>
                  <th style={{ width: "150px", textAlign: "right" }}>Rate</th>
                  <th style={{ width: "150px", textAlign: "right" }}>Amount</th>
                  <th style={{ width: "100px", textAlign: "right" }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {invoiceDetails &&
                  invoiceDetails.map((detail, rowNumber) => (
                    <tr key={rowNumber}>
                      <td>
                        <div className="d-flex justify-content-between">
                          <Select
                            className="w-100 text-start"
                            options={ChargeOptions.map((option, index) => ({
                              value: option.chargeID,
                              label: option.chargeName,
                            }))}
                            onChange={(option) =>
                              handleChargeChange(option, rowNumber)
                            }
                            value={
                              selectedChargeNames[rowNumber] // Use selectedChargeNames array for each row
                                ? {
                                  value: invoiceDetails[rowNumber]?.ChargeID,
                                  label: selectedChargeNames[rowNumber],
                                }
                                : null
                            }
                            placeholder="Select Charge Name"
                          />
                          <div className="selectEndIcon input-group-text">
                            <i
                              className="fa fa-plus plusIcon"
                              onClick={() => {
                                setIsChargeModalOpen(true);
                                setRowNumberForNewChargeId(rowNumber);
                              }}
                            ></i>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          name="Remarks"
                          placeholder="Remarks"
                          value={detail.Remarks}
                          onChange={(e) =>
                            handleInputChangeOfDetails(e, rowNumber)
                          }
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          name="Qty"
                          placeholder="Enter Qty"
                          value={detail.Qty}
                          onChange={(e) =>
                            handleInputChangeOfDetails(e, rowNumber)
                          }
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          name="Rate"
                          placeholder="Enter Rate"
                          value={detail.Rate}
                          onChange={(e) =>
                            handleInputChangeOfDetails(e, rowNumber)
                          }
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          name="Amount"
                          placeholder="Enter Amount"
                          value={detail.Amount}
                          disabled
                        />
                      </td>
                      <td className="text-center">
                        <Button
                          variant="outline-danger "
                          size="sm"
                          onClick={() => removeRow(rowNumber)}
                        >
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div className="text-end">
              <Button onClick={addRow} className="outline-primaryOfTapti">
                Add
              </Button>
            </div>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <span>Payment Information</span>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={5} sm={5} lg={5}>
              <Form.Group controlId="Invoice_Name">
                <InputGroup className="mb-3">
                  <Form.Control
                    as="textarea" // Set the 'as' prop to 'textarea'
                    name="Remarks"
                    rows={10}
                    placeholder="Add Customer Notes"
                    value={formData.Remarks}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={7} sm={7} lg={7}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={6} className="text-start">
                      <Form.Label> Sub Total :</Form.Label>
                    </Col>
                    <Col md={6} className="text-end fw-bold">
                      ₹.{formData.Gross_Amount}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={3} className="text-start">
                      <Form.Label> Discount :</Form.Label>
                    </Col>
                    <Col md={4} className="text-end">
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <i class="fa fa-percentage"></i>
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="Disc_Per"
                          placeholder="Enter Disc_Per"
                          value={formData.Disc_Per}
                          onChange={handleDiscount}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={4} className="text-end">
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <i class="fa fa-inr"></i>
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="Disc_Amount"
                          placeholder="Enter Disc_Amount"
                          value={formData.Disc_Amount}
                          onChange={handleDiscount}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6} className="text-start">
                      <Form.Label>
                        {" "}
                        Total Received :{" "}
                        <span>₹{formData.Total_ReceivedAmount}</span>
                      </Form.Label>
                    </Col>
                    <Col md={6} className="text-end">
                      <Form.Label>
                        {" "}
                        Due Amount : <span>₹{formData.Due_Amount}</span>
                      </Form.Label>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col md={6} className="text-start">
                      <Form.Label>Total Amount :</Form.Label>
                    </Col>
                    <Col md={6} className="text-end">
                      <Form.Label>₹.{formData.Net_Amount}</Form.Label>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={3}></Col>
            <Col md={1} sm={1} lg={1} className="text-end">
              <Form.Group controlId="Invoice_Name">
                <Form.Label>Payment:</Form.Label>
              </Form.Group>
            </Col>
            <Col md={2} sm={2} lg={2}>
              <Form.Group controlId="PaymentType_ID">
                <div className="mb-3 d-flex justify-content-between">
                  <div
                    className="selectStartIcon input-group-text"
                    id="basic-addon1"
                  >
                    <i className="fas fa-credit-card"></i>
                  </div>
                  <Select
                    className="w-100 rounded-end"
                    options={paymentTypeOptions.map((option) => ({
                      value: option.paymentTypeID,
                      label: option.paymentName,
                    }))}
                    value={selectedPaymentOption}
                    onChange={handlePaymentType}
                    placeholder="Mode"
                    required
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={2} sm={2} lg={2}>
              <Form.Group controlId="Invoice_Name">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-inr"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="Received_Amount"
                    placeholder="Enter Amount"
                    value={formData.Received_Amount}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={4} sm={4} lg={4}>
              <Form.Group controlId="Invoice_Name">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="Invoice_ReceivedDate"
                    value={formData.Invoice_ReceivedDate}
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <div className="text-center">
          <Button
            type="submit"
            variant="primary"
            className="my-3"
            onClick={insertOrUpdateInvoice}
          >
            {formData?.Invoice_ID > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Card>

      <Modal
        show={isChargeModalOpen}
        onHide={closeChargeModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New Charge
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <ChargeComponent ChargeID={closeChargeModal}></ChargeComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
