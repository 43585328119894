import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api, { handleApiError } from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import ClientComponent from "./ClientComponent";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { getCurrentDate } from "../../Utils/commonFunction";
import TypeaheadComponent from "../../Components/Common/TypeaheadComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";
import Loader from "../../Components/Common/Loader ";
import ReactDOM from "react-dom";

export default function Client() {
  const DateTimeFilterData = {
    start_date: "",
    end_date: "",
    ClientID: 0,
  };

  const [clientData, setClientData] = useState([]);
  const [editClientData, setEditClientData] = useState(null);
  const [formData, setFormData] = useState(DateTimeFilterData);
  const [selectedType, setSelectedType] = useState("All");
  const [refreshKey, setRefreshKey] = useState(0);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");
  const [ClientListForPrint, setClientListForPrint] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //let isLoading = useRef(false);

  useEffect(() => {
    getClientAllCLientDefaultAGGridData();
    CheckRoleTemplatePermissions("Client")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  const getClientAllCLientDefaultAGGridData = () => {
    api
      .get("Client/AllClient")
      .then((result) => {
        setClientData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetForm = () => {
    setFormData(DateTimeFilterData);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTypeChange = (selectedValue) => {
    setSelectedType(selectedValue);
  };

  const notifySuccess = (message) => toast.success(message);

  const getClient = () => {
    setIsLoading(true);
    api
      .get(
        `Client/AllClientDateWise?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}&Type=${selectedType}&ClientID=${formData.ClientID}`
      )
      .then(({ data }) => setClientData(data.data))
      .catch(handleApiError)
      .finally(() => setIsLoading(false));
  };

  const getSingleClient = (id) => {
    api
      .get(`Client/SingleClient?ClientID=${id}`)
      .then(({ data }) => setEditClientData(data.data))
      .catch(handleApiError);
  };

  const deleteClient = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Client/DeleteClient?ClientID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getClient();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  const handleClientSelection = (selectedClient) => {
    if (selectedClient) {
      setFormData((prevData) => ({ ...prevData, ClientID: selectedClient }));
    }
  };

  // const PrintClient = () => {
  //   api
  //     .get(`Client/AllClientForPrint`)
  //     .then(({ data }) => {
  //       var clientList = data.data;
  //       setClientListForPrint(data.data);
  //       let printWindow = window.open("", "_blank");
  //       const printableContent = generatePrintableClientList(clientList);
  //       printWindow.document.write(printableContent);
  //       printWindow.document.close();
  //       printWindow.print();
  //     })
  //     .catch(handleApiError);
  // };

  const PrintPreview = () => {
    return (
      <>
        <div style={{}}>
          <table
            className="table table-bordered"
            style={{
              fontSize: "12px",
              lineHeight: "8px",
              marginTop: "-1px",
              borderColor: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={16}
                  style={{ textAlign: "center", fontSize: "14px" }}
                >
                  Client List
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>No</th>
                <th style={{ textAlign: "center" }}>Client Name</th>
                <th style={{ textAlign: "center", minWidth: "140px" }}>
                  ContactNo.
                </th>
                <th style={{ textAlign: "center", minWidth: "10px" }}>Area</th>
              </tr>
            </thead>
            <tbody>
              {clientData.map((client, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>{index + 1}</td>
                  <td style={{ textAlign: "center" }}>{client.clientName}</td>
                  <td style={{ textAlign: "center", minWidth: "140px" }}>
                    {client.clientMobileNumber}
                  </td>
                  <td style={{ textAlign: "center" }}>{client.areaName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const printClientReport = () => {
    const ClientDetailsForPrint = clientData;

    const printWindow = window.open("", "_blank");

    printWindow.document.write(
      '<html><head><title>Print Barcode</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"><style>{{width:"760px;"}}</style></head><body>'
    );

    ReactDOM.render(
      <PrintPreview stoneDetails={ClientDetailsForPrint} />,
      printWindow.document.body
    );

    printWindow.document.write("</body></html>");
  };

  const columnDefs = [
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: "Client  Name",
      field: "clientName",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 350,
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: "MobileNumber",
      field: "clientMobileNumber",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 180,
      flex: 1,
      suppressMovable: true,
    },
    // {
    //   headerName: "EmailID",
    //   field: "emailID",
    //   sortable: true,
    //   filter: true,
    //   headerClass: "widthTableColum",
    //   wrapHeaderText: true,
    //   autoHeaderHeight: true,
    //   wrapText: true,
    //   autoHeight: true,
    //   cellStyle: { textAlign: "left" },
    //   minWidth: 250,
    //   flex: 1,
    //   suppressMovable: true,
    // },
    {
      headerName: "RemoteDesk",
      field: "remoteDeskAddress",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 150,
      flex: 1,
      suppressMovable: true,
      hide: selectedType === "Lead",
    },
    {
      headerName: "Area",
      field: "areaName",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 300,
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: "IsActive",
      field: "isActive",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
      flex: 1,
      suppressMovable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "clientID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleClient(value)}
              ></ion-icon>
            ),
            pinned: "right",
            suppressMovable: true,
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "clientID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteClient(value)}
              ></ion-icon>
            ),
            pinned: "right",
            suppressMovable: true,
          },
        ]
      : []),
  ];

  return (
    <>
      <ToastContainer />
      {isLoading && <Loader />}
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Client</span>
          </Card.Header>
          <Card.Body>
            <ClientComponent
              ClientID={getClient}
              editedClientData={editClientData}
              // getClientData={getSingleClient}
              onClear={() => setEditClientData(null)}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <Row className="justify-content-between align-items-center">
            <Col xs="auto">
              <span style={{ fontWeight: "800" }}>Client List</span>
            </Col>
            <Col xs="auto" className="flex-grow-1">
              <Row className="justify-content-center align-items-center">
                <Col md={3} sm={3} lg={3}>
                  <Form.Group
                    controlId="Expected_ResolveDate"
                    className="d-flex"
                  >
                    <InputGroup className="my-1">
                      <InputGroup.Text id="basic-addon1">
                        <i className="fa fa-calendar"></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="date"
                        name="start_date"
                        value={formData.start_date}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Expected start_date
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={4} sm={3} lg={3}>
                  <Form.Group
                    controlId="Expected_ResolveDate"
                    className="d-flex"
                  >
                    <InputGroup className="my-1">
                      <InputGroup.Text id="basic-addon1">
                        <i className="fa fa-calendar"></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="date"
                        name="end_date"
                        value={formData.end_date}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Expected end_date
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={4} sm={3} lg={2}>
                  <Form.Group
                    controlId="Expected_ResolveDate"
                    className="d-flex"
                  >
                    <InputGroup className="my-1">
                      <TypeaheadComponent
                        type={"Both"}
                        onClientSelect={handleClientSelection}
                        onClear={refreshKey}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs="auto">
                  <Dropdown
                    className="clientDD"
                    onSelect={(eventKey) => handleTypeChange(eventKey)}
                  >
                    <Dropdown.Toggle
                      variant="outline-primary"
                      id="dropdown-basic"
                    >
                      {selectedType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="All">All</Dropdown.Item>
                      <Dropdown.Item eventKey="Client">Client</Dropdown.Item>
                      <Dropdown.Item eventKey="Lead">Lead</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="outline-success"
                    style={{ padding: "2px 18px", height: "40px" }}
                    onClick={getClient}
                  >
                    Search
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="outline-info"
                    style={{ padding: "2px 23px", height: "40px" }}
                    onClick={() => printClientReport()}
                  >
                    Print
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={clientData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="70vh"
          />
        </Card.Body>
      </Card>
    </>
  );
}
